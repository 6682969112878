@import "ember-cli-bootstrap-4/bootstrap";
@import "admin-communities/buttons";
@import "admin-communities/new-community";
@import "ember-searchable-select/style";
@import "admin-communities/community-tabs";

@import "animate.min";
@import "shortcodes";
@import "move_1_column_4";

/* LOADING */
#loading{background-color:rgba(255,255,255,0.7); height: 100%; left: 0; opacity: 0.9; position: fixed; top: 0; width: 100%; z-index: 9999; display: block; } 
#loader {position: absolute; top: calc(50% - 20px); left: calc(50% - 20px); } @keyframes loader {0% { left: -100px } 100% { left: 110%; } } #box {width: 50px; height: 50px;  animation: animate .5s linear infinite; position: absolute; top: 0; left: 0; border-radius: 3px; } @keyframes animate {17% { border-bottom-right-radius: 3px; } 25% { transform: translateY(9px) rotate(22.5deg); } 50% {transform: translateY(18px) scale(1,.9) rotate(45deg) ; border-bottom-right-radius: 40px; } 75% { transform: translateY(9px) rotate(67.5deg); } 100% { transform: translateY(0) rotate(90deg); } } #shadow {width: 50px; height: 5px; background: #000; opacity: 0.1; position: absolute; top: 59px; left: 0; border-radius: 50%; animation: shadow .5s linear infinite; } @keyframes shadow {50% {transform: scale(1.2,1); } } /* END OF LOADING */

*{
	font-family: 'Open Sans', sans-serif;
}
body{padding-top: 70px;}
a:hover{text-decoration:none;color:#333;}
.modal{
	max-width:none;
	background:none;
}
.carousel-item a{color:inherit;text-decoration:none;}
img.lazy:before { 
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  height: calc(100% + 10px);
  width: 100%;
  background-color: rgb(230, 230, 230);
  background-image: url(/img/loading.gif);
  background-size:contain;
  background-repeat: no-repeat;
  border: 2px dotted rgb(200, 200, 200);
  border-radius: 5px;
  min-height:200px;
  background-position: center;
}

#home_slider img.lazy:not([src]),#home_slider img.loading,#home_slider img.lazy:before { 
  content: " ";
  display: inline-block;
  position: relative;
  width: 100%;
  background-color: rgb(230, 230, 230);
  background-image: url(/img/loading.gif);
  background-size:contain;
  background-repeat: no-repeat;
  border: 2px dotted rgb(200, 200, 200);
  border-radius: 5px;
  min-height:600px;
  margin-bottom:-50px;
  background-position: center;

}
#modal-avisos{z-index: 1100;}

/*Uploading*/
.cont-upload-image{
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    padding: 20px;
    border: 3px dashed #adadad;
    background-color: #f7f7f7;
}
